/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <ColumnCover name={"menu"}>
          
          <Column className="--menu pb--06 pt--20" anim={null} menu={true} animS={null} border={null}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box" style={{"maxWidth":""}}>
                
                <Subtitle className="subtitle-box fs--20 w--600 swpf--uppercase lh--14 pl--0 pr--0" style={{"maxWidth":""}} content={"Čištění DPF Ostrava"}>
                </Subtitle>

                <Subtitle className="subtitle-box fs--18 subtitle-box--invert lh--1 mt--0 pl--0 pr--0" content={"<span style=\"color: rgb(213, 164, 106);\">228 227 043</span>"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/otazky-a-odpovedi"} target={""} content={"Otázky a odpovědi"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/kontakt"} target={""} content={"Kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn1 btn-box--pbtn3 btn-box--shape3 fs--18 w--500 swpf--uppercase lh--1 pl--08 pr--08" innerClassName="pb--10 pt--10" href={"/objednavka"} target={""} content={"Vyčistit filtr"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="pb--0 pt--0" name={"sluzby-1"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}}>
        </Column>


        <Column className="--center pb--60 pt--80" name={"uwhjufg46ks"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--10 pl--40 pr--40 pt--50 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--48 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Kontakt</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" name={"1p1yygx8jri"}>
          
          <ColumnWrap className="column__flex --center el--2 pb--20 pl--40 pr--40 flex--stretch" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="--hvr2 --shape3 --border2 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={"2"}>
              
              <Title className="title-box fs--30 w--600 ls--004" style={{"maxWidth":316}} content={"info@cisteni-dpf-ostrava.cz&nbsp;<br>"}>
              </Title>

              <Text className="text-box w--500 ls--001 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(109, 112, 134);\">Na e-maily reagujeme v pracovní dny, zpravidla do 24 hodin.&nbsp;&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape3 --border2 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={"2"}>
              
              <Title className="title-box fs--30 w--600 ls--004 lh--2" style={{"maxWidth":316}} content={"228 227 043<br>"}>
              </Title>

              <Text className="text-box w--500 ls--001 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(109, 112, 134);\">Telefonický nás zastihnete v pracovní dny od 7:00 do 16:00.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-wwg8dr --parallax pb--80 pt--80" name={"p0zcyis97o"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--40 pt--40" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":910}} content={"Poptejte si čištění DPF filtru ješte dnes."}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3" href={"/objednavka"} content={"Poptat nyní"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80">
          
          <ColumnWrap className="column__flex --left el--4 pl--40 pr--40 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--48 w--600 swpf--uppercase ls--002 lh--12 mt--0 pl--0" content={"<span style=\"color: rgb(44, 39, 63);\">Čištění DPF</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 ls--002 mt--02 pl--0" content={"<span style=\"color: rgb(213, 164, 106);\">Komplexní služby Ostrava</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--18 w--600 lh--12 mt--0 pl--0" content={"<span style=\"color: rgb(213, 164, 106);\">Služba</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--25 pl--0 pr--0" href={"#cenik"} content={"Ceník čištění DPF"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--16 pl--0 pr--0" href={"#jak"} content={"Jak to funguje"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--16 pl--0 pr--0" href={"/otazky-a-odpovedi"} content={"Otázky a odpovědi"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--16 pl--0 pr--0" href={"/objednavka"} content={"Poptat čištění"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--18 w--600 lh--12 mt--0 pl--0" content={"<span style=\"color: rgb(213, 164, 106);\">Kontakt</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--25 pl--0 pr--0" content={"228 227 043"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--06 pl--0 pr--0" content={"info@cisteni-dpf-ostrava.cz"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn4 btn-box--shape3 btn-box--filling2 fs--18 ls--002 mt--25 pl--0 pr--0" href={"/objednavka"} content={"Poptat čištění"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}